#app-footer {
  display:inline-block;
  background-color:#000000;
  height:50px;
  width:100%;
  margin-bottom:-36px;
}
#app-footer a {
  text-decoration:none;
  font-size:14px;
  color:#ffffff!important;
  margin-left:25px;
  margin-right:10px;
}
#fusszeile {
  float:right;
  display:flex;
  justify-content: center;
  align-items: center;
  height:100%;
}
html, body, #root {
  height:98%;
}
